"use client"

import { useParams } from "next/navigation"
import { isBrowser } from "src/utilities/env"
import { AppLocale, DEFAULT_COUNTRY_CODE, getLocaleForCountryCode } from "./localization"

function extractCountryCodeFromPathname(pathname: string) {
  const countryCodeSegment = pathname.split("/")[1]

  if (countryCodeSegment?.length === 2) {
    return countryCodeSegment
  }

  return DEFAULT_COUNTRY_CODE
}

export function getCountryCode() {
  if (!isBrowser()) {
    return DEFAULT_COUNTRY_CODE
  }

  const pathname = window.location.pathname
  const countryCode = extractCountryCodeFromPathname(pathname)

  return countryCode
}

export function getLocale() {
  const countryCode = getCountryCode()

  return getLocaleForCountryCode(countryCode)
}

export function useCountryCode() {
  return (useParams().countryCode as string) || DEFAULT_COUNTRY_CODE
}

export function useLocale() {
  const countryCode = useCountryCode()

  return getLocaleForCountryCode(countryCode)
}

export function useLocalizedText<T>(allTexts: Record<AppLocale, T>) {
  const locale = useLocale()

  return allTexts[locale]
}

export function formatLocalizedPath(path: string) {
  const countryCode = getCountryCode()
  const localizedHref = countryCode === DEFAULT_COUNTRY_CODE ? path : `/${countryCode}${path}`

  return localizedHref
}
