export const DEFAULT_COUNTRY_CODE = "de"
export const DEFAULT_LOCALE: AppLocale = "de"
export const FALLBACK_LOCALE: AppLocale = "en"

export const LOCALES = ["de", "en"] as const

export type AppLocale = (typeof LOCALES)[number]

const regionToLocale: Record<string, AppLocale> = {
  de: "de",
  at: "de",
  ch: "de",
}

export function getLocaleForCountryCode(countryCode: string) {
  return regionToLocale[countryCode] || FALLBACK_LOCALE
}
